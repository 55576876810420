import React from 'react';
import {Col, Grid, Row} from "react-flexbox-grid";
import {IconButton} from "@mui/material";
import AddIcon from '@mui/icons-material/Add';

const SideScroll = () => {
    return (
        <div className="middle-footer">
            <div className="container" >
                <Grid fluid>
                    <Row style={{  paddingBottom: "50px"}}>
                        <Col lg={6} >
                            <h1 style={{paddingTop: "7%", paddingLeft: "25px"}}>Why work with us </h1>
                        </Col>
                    </Row>
                    <Row >
                        <div className={ "scrollbar-custom" } style={{ width: "100%",
                            display: "-webkit-box",
                            overflowY: "auto",
                            transform: "rotateX(180deg)",
                            marginBottom: "100px",
                            marginLeft: "14px",
                            maxWidth: "97%"
                        }}>

                            <div style={{ maxWidth: "300px", padding: "30px", transform: "rotateX(180deg)"}}>
                                <h3 style={{ minHeight: "40px"}}> Flexible Services adapted to your needs</h3>
                              <p style={{ textAlign: 'justify' }}>
                                Our platform is a fully flexible solution, which can be adapted to meet the specific
                                needs of our clients by integrating with all major security products.
                                This means that businesses can choose the level of protection and the specific security capabilities that are most
                                important to them,
                                and we will tailor our solution to meet those needs.
                              </p>
                              <IconButton style={{ position: "relative", left: "-12px" }}>
                                    <AddIcon/>
                                </IconButton>
                            </div>

                            <div style={{ maxWidth: "300px", padding: "30px",  transform: "rotateX(180deg)"}}>
                                <h3 style={{ minHeight: "40px"}}> Dedicated full SOC team</h3>
                              <p style={{ textAlign: 'justify' }}>
                                A dedicated team of certified cybersecurity professionals who are responsible for monitoring and protecting our
                                clients' networks and systems.
                                This means that businesses benefit from the expertise and experience of our team, without the need to build and
                                maintain their own in-house security team.
                              </p>
                              <IconButton style={{ position: "relative", left: "-12px" }}>
                                    <AddIcon/>
                                </IconButton>
                            </div>

                            <div style={{ maxWidth: "300px", padding: "30px", transform: "rotateX(180deg)"}}>
                                <h3 style={{ minHeight: "40px"}}> Certified Cybersecurity Professionals</h3>
                              <p style={{ textAlign: 'justify' }}>
                                Our SOC team members are certified cybersecurity professionals, which means that they have the skills, expertise, and
                                knowledge necessary to protect your networks and systems effectively.
                                This ensures that businesses are protected against the latest cyber threats, and can trust that their security is in
                                the hands of experienced professionals.

                              </p>
                              <IconButton style={{ position: "relative", left: "-12px" }}>
                                    <AddIcon/>
                                </IconButton>
                            </div>

                            <div style={{ maxWidth: "300px", padding: "30px",  transform: "rotateX(180deg)"}}>
                                <h3 style={{ minHeight: "40px"}}> High ROI</h3>
                                <p style={{ textAlign: 'justify' }}>
                                  Lowering costs: Eliminates the need for in-house security teams, hardware, and software licenses.
                                  Saving time: Offers a 24/7 ready-made security team, removing the need for hiring and training.
                                  Advanced security capabilities: Includes machine learning and threat intelligence to protect against the latest cyber threats.
                                </p>
                                <IconButton style={{ position: "relative", left: "-12px" }}>
                                    <AddIcon/>
                                </IconButton>
                            </div>

                            <div style={{ maxWidth: "300px", padding: "30px", transform: "rotateX(180deg)"}}>
                                <h3 style={{ minHeight: "40px"}}> Cloud based platform</h3>
                              <p style={{ textAlign: 'justify' }}> We run a cloud-based platform, which means that can be
                                accessed from anywhere and on any device
                              </p>
                              <IconButton style={{ position: "relative", left: "-12px" }}>
                                    <AddIcon/>
                                </IconButton>
                            </div>

                        </div>
                    </Row>
                </Grid>
            </div>
        </div>
    );
};

export default SideScroll;
