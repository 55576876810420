import React from 'react';
import {Col, Grid, Row} from "react-flexbox-grid";
import {ReactComponent as Badge1} from '../../svg/badge1.svg';
import {ReactComponent as Badge2} from '../../svg/badge2.svg';
import {useMediaQuery} from "react-responsive";

const StartFooter = () => {

    const matches = useMediaQuery({ query: '(min-width: 992px)' });

    return (
        <div className="bottom-footer gradient-left-to-right">
            <div className="container" >
                <Grid fluid>
                    <Row style={{ paddingTop: matches ? "50px": "0px", paddingBottom: "50px"}}>
                        <Col lg={6}
                             style={{textAlign: matches ? "left" : "center", paddingLeft: matches ? "30px" : "0px"}}>
                            <h1 style={{ paddingTop: "7%"}}>Certified quality </h1>
                            <p style={{ marginTop: "-10px"}}> We are ISO 9001/27001 certified.</p>
                        </Col>
                        <Col lg={6} style={{ textAlign: matches ? "end" : "center", marginTop: matches ? "0px" : "30px"}}>
                            <Badge1 style={{ padding: "20px", width: "17vh", height: "17vh"}}/>
                            <Badge2 style={{ padding: "20px", width: "17vh", height: "17vh"}}/>
                        </Col>
                    </Row>
                </Grid>
            </div>
        </div>
    );
};

export default StartFooter;