import React, {useEffect} from 'react';
import HeroImage from "../components/hero image/HeroImage";
import Footer from "../components/footer/Footer";
import WhyUsFull from "../components/whyusfull/WhyUsFull";

const WhyUs = ({setMenuWhiteTheme}) => {

    useEffect(() => {
        setMenuWhiteTheme(true);
    }, []);

    return (
        <div>
            <HeroImage title="" description="Why us" />
            <WhyUsFull/>
            <Footer/>
        </div>
    );
};

export default WhyUs;
