import React from 'react';
import {Col, Grid, Row} from "react-flexbox-grid";

import {ReactComponent as Icon1} from '../../svg/icons/Icon1.svg';
import {ReactComponent as Icon2} from '../../svg/icons/Icon2.svg';
import {ReactComponent as Icon3} from '../../svg/icons/Icon3.svg';
import {ReactComponent as Icon4} from '../../svg/icons/Icon4.svg';
import {ReactComponent as Icon5} from '../../svg/icons/Icon5.svg';
import {ReactComponent as Icon6} from '../../svg/icons/Icon6.svg';
import {ReactComponent as Icon7} from '../../svg/icons/Icon7.svg';
import {ReactComponent as Icon8} from '../../svg/icons/Icon8.svg';
import {ReactComponent as Icon9} from '../../svg/icons/Icon9.svg';
import {ReactComponent as Icon10} from '../../svg/icons/Icon10.svg';
import {ReactComponent as Icon11} from '../../svg/icons/Icon11.svg';
import {ReactComponent as Icon12} from '../../svg/icons/Icon12.svg';
import {useMediaQuery} from "react-responsive";

const FullFeatures = () => {

    const matches = useMediaQuery({ query: '(min-width: 992px)' });
    const matches1 = useMediaQuery({ query: '(min-width: 590px)' });


    return (
        <div className="middle-footer  gradient-bottom-to-top" style={{ backgroundColor: "gray"}}>
            <div className="container" >
                <Grid fluid>

                    <Row style={{ paddingTop: "50px", paddingBottom: "10px" }}>
                        <Col lg={4}>
                            <div style={{ margin: "10px", padding: "30px", height: matches1 ? "20vh" : "auto"}}>
                                <Row style={{ textAlign: matches ? "justify" : "center"}}>
                                    <Col lg={12}>
                                        <Icon1 style={{ marginTop: "10px"}}/>
                                    </Col>
                                    <Col lg={12}>
                                        <h3 >24x7 CyberSecurity Monitoring</h3>
                                        <span> Provides constant surveillance of company's network and systems to identify and respond to potential threats in real-time. This includes monitoring for suspicious activity, such as unusual login attempts or changes to sensitive data, and alerting security personnel when potential threats are detected. </span>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                        <Col lg={4}>
                            <div style={{ margin: "10px", padding: "30px", height: matches1 ? "20vh" : "auto"}}>
                                <Row style={{ textAlign: matches ? "justify" : "center"}}>
                                    <Col lg={12}>
                                        <Icon2 style={{ marginTop: "10px"}}/>
                                    </Col>
                                    <Col lg={12}>
                                        <h3 >Threat Detection & Investigation assisted by ML</h3>
                                        <span> We use machine learning algorithms to detect and investigate potential security threats automatically. By analyzing large amounts of data in real-time, the system can identify patterns or anomalies that may indicate the presence of a threat and alert the appropriate team for further investigation. </span>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                        <Col lg={4}>
                            <div style={{ margin: "10px", padding: "30px", height: matches1 ? "20vh" : "auto"}}>
                                <Row style={{ textAlign: matches ? "justify" : "center"}}>
                                    <Col lg={4}>
                                        <Icon3 style={{ marginTop: "10px"}}/>
                                    </Col>
                                    <Col lg={12}>
                                        <h3 >Incident Response</h3>
                                        <span> In the event of a security incident, we use a structured and organized approach to respond to the situation. The process involves identifying the cause of the incident, containing and mitigating the damage, and restoring any affected systems or data. </span>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>


                    <Row style={{paddingTop: "100px", paddingBottom: "50px"}}>
                        <Col lg={4}>
                            <div style={{ margin: "10px", padding: "30px", height: matches1 ? "20vh" : "auto"}}>
                                <Row style={{ textAlign: matches ? "justify" : "center"}}>
                                    <Col lg={12}>
                                        <Icon4 style={{ marginTop: "10px"}}/>
                                    </Col>
                                    <Col lg={12}>
                                        <h3 >Mitigation & Remediation & host isolation</h3>
                                        <span> After a security incident has been identified and contained, the security team focuses on reducing the impact of the incident and preventing it from happening again. This includes implementing additional security measures where necessary, patching vulnerable systems, or isolating affected hosts to prevent the spread of the threat. </span>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                        <Col lg={4}>
                            <div style={{ margin: "10px", padding: "30px", height: matches1 ? "20vh" : "auto"}}>
                                <Row style={{ textAlign: matches ? "justify" : "center"}}>
                                    <Col lg={12}>
                                        <Icon5 style={{ marginTop: "10px"}}/>
                                    </Col>
                                    <Col lg={12}>
                                        <h3 >Reporting and Analytics</h3>
                                        <span> We provide detailed reporting and analysis of security incidents and trends, helping your company to understand the current state of its security posture and identify areas for improvement. </span>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                        <Col lg={4}>
                            <div style={{ margin: "10px", padding: "30px", height: matches1 ? "20vh" : "auto"}}>
                                <Row style={{ textAlign: matches ? "justify" : "center"}}>
                                    <Col lg={12}>
                                        <Icon6 style={{ marginTop: "10px"}}/>
                                    </Col>
                                    <Col lg={12}>
                                        <h3 >Threat Intelligence</h3>
                                        <span>By collecting and analyzing intelligence about potential security threats, such as new vulnerabilities or e merging attack techniques helps organizations stay ahead of potential threats and take proactive steps to protect their systems and data.</span>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>


                    <Row style={{paddingTop: "100px", paddingBottom: "50px"}}>
                        <Col lg={4}>
                            <div style={{ margin: "10px", padding: "30px", height: matches1 ? "20vh" : "auto"}}>
                                <Row style={{ textAlign: matches ? "justify" : "center"}}>
                                    <Col lg={12}>
                                        <Icon7 style={{ marginTop: "10px"}}/>
                                    </Col>
                                    <Col lg={12}>
                                        <h3 >Notification and Alerting</h3>
                                        <span> We ensure that the appropriate parties are notified in the event of a security incident. This includes sending alerts to designated individuals or teams or automatically triggering an incident response plan. </span>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                        <Col lg={4}>
                            <div style={{ margin: "10px", padding: "30px", height: matches1 ? "20vh" : "auto"}}>
                                <Row style={{ textAlign: matches ? "justify" : "center"}}>
                                    <Col lg={12}>
                                        <Icon8 style={{ marginTop: "10px"}}/>
                                    </Col>
                                    <Col lg={12}>
                                        <h3 >Insider Threat Detection</h3>
                                        <span>
                                            Insider Threat Detection focuses on detecting and responding to threats that originate from within an organization, such as employees or contractors with malicious intent or compromised accounts. Our platform includes UBA (User Behavior Analytics)
                                        </span>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                        <Col lg={4}>
                            <div style={{ margin: "10px", padding: "30px", height: matches1 ? "20vh" : "auto"}}>
                                <Row style={{ textAlign: matches ? "justify" : "center"}}>
                                    <Col lg={12}>
                                        <Icon9 style={{ marginTop: "10px"}}/>
                                    </Col>
                                    <Col lg={12}>
                                        <h3 >Pro-Active Threat Hunting</h3>
                                        <span>
                                            Our red team proactively searches for potential security threats that may not have been detected by other security measures. This involves using a combination of manual and automated techniques to identify and investigate potential threats.
                                        </span>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>


                    <Row style={{paddingTop: "100px", paddingBottom: "160px"}}>
                        <Col lg={4}>
                            <div style={{ margin: "10px", padding: "30px", height: matches1 ? "20vh" : "auto"}}>
                                <Row style={{ textAlign: matches ? "justify" : "center"}}>
                                    <Col lg={12}>
                                        <Icon10 style={{ marginTop: "10px"}}/>
                                    </Col>
                                    <Col lg={12}>
                                        <h3 >Vulnerability Management</h3>
                                        <span>
                                            This involves identifying, prioritizing, and mitigating vulnerabilities in the company's systems and applications. This involves regularly scanning for vulnerabilities, applying patches or updates, and implementing additional security controls.
                                        </span>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                        <Col lg={4}>
                            <div style={{ margin: "10px", padding: "30px", height: matches1 ? "20vh" : "auto"}}>
                                <Row style={{ textAlign: matches ? "justify" : "center"}}>
                                    <Col lg={12}>
                                        <Icon11 style={{ marginTop: "10px"}}/>
                                    </Col>
                                    <Col lg={12}>
                                        <h3 >Continuous Security Posture</h3>
                                        <span>
                                            Using our proprietary software SERP (Security Event and Response Platform) our clients won’t lose their security posture no matter what.
                                        </span>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                        <Col lg={4}>
                            <div style={{ margin: "10px", padding: "30px", height: matches1 ? "20vh" : "auto"}}>
                                <Row style={{ textAlign: matches ? "justify" : "center"}}>
                                    <Col lg={12}>
                                        <Icon12 style={{ marginTop: "10px"}}/>
                                    </Col>
                                    <Col lg={12}>
                                        <h3 >Custom security software development</h3>
                                        <span>
                                            We create unique software to meet specific security needs including firewalls, intrusion detection, and encryption to protect against specific threats
                                        </span>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>

                </Grid>
            </div>
        </div>
    );
};

export default FullFeatures;
