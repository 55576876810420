import React from 'react';
import { Col, Grid, Row } from "react-flexbox-grid";
import { ReactComponent as Icon1 } from '../../svg/text_icons/icon1.svg';
import { ReactComponent as Icon2 } from '../../svg/text_icons/icon2.svg';
import { ReactComponent as Icon3 } from '../../svg/text_icons/icon3.svg';
import { ReactComponent as Icon4 } from '../../svg/text_icons/icon4.svg';

import './WhyUsFull.css'; // Import the CSS file

// Reusable Icon Component
const IconBox = ({ IconComponent }) => (
    <Col lg={3} className="icon-container">
        <Row className="icon-wrapper">
            <IconComponent className="icon-style" />
        </Row>
    </Col>
);

// Reusable Feature Component
const FeatureBox = ({ title, description }) => (
    <Col lg={4} className="feature-container__wrapper">
        <div className="feature-container">
            <Row>
                <div className="feature-highlight"></div>
            </Row>
            <Row className="feature-content">
                <Col lg={12}>
                    <h3>{title}</h3>
                    <span>{description}</span>
                </Col>
            </Row>
        </div>
    </Col>
);

const featureData = [
    {
        title: "24x7 CyberSecurity Monitoring",
        description: `Provides constant surveillance of the company's network and systems to identify and respond to potential threats in real-time.
        This includes monitoring for suspicious activity, such as unusual login attempts or changes to sensitive data, and alerting security personnel when potential threats are detected.`
    },
    {
        title: "Threat Detection & Investigation assisted by ML",
        description: `We use machine learning algorithms to detect and investigate potential security threats automatically.
        By analyzing large amounts of data in real-time, the system can identify patterns or anomalies that may indicate the presence of a threat and alert the appropriate team for further investigation.`
    },
    {
        title: "Incident Response",
        description: `In the event of a security incident, we use a structured and organized approach to respond to the situation.
        The process involves identifying the cause of the incident, containing and mitigating the damage, and restoring any affected systems or data.`
    },
    {
        title: "XDR & SIEM included",
        description: `Our MDR services include SIEM and XDR, covering cloud and license costs.
        Benefit from real-time threat detection, compliance, and advanced protection across multiple security layers—all with predictable, all-inclusive pricing.`
    },
    {
        title: "Insider Threat Detection",
        description: `Insider Threat Detection focuses on detecting and responding to threats that originate from within an organization,
        such as employees or contractors with malicious intent or compromised accounts. Our platform includes UEBA (User and Entity Behavior Analytics).`
    },
    {
        title: "Customizable Solutions",
        description: `Our MDR services are tailored to meet the specific security needs of each client, ensuring that they receive the best possible protection.`
    },
];

const WhyUsFull = () => {
    return (
        <div className="why_us">
            <div className="container">
                <Grid fluid>
                    <Row className="why-us-icons-row">
                        {[Icon1, Icon2, Icon3, Icon4].map((Icon, idx) => (
                            <IconBox key={idx} IconComponent={Icon} />
                        ))}
                    </Row>

                    <div className="gradient-top-to-bottom">
                        <Row className="feature-row">
                            {featureData.map((feature, idx) => (
                                <FeatureBox key={idx} title={feature.title} description={feature.description} />
                            ))}
                        </Row>
                    </div>
                </Grid>
            </div>
        </div>
    );
};

export default WhyUsFull;
