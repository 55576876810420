import React from 'react';
import {Col, Grid, Row} from "react-flexbox-grid";
import {useMediaQuery} from "react-responsive";

import {ReactComponent as Icon1} from '../../svg/text_icons/icon1.svg';
import {ReactComponent as Icon2} from '../../svg/text_icons/icon2.svg';
import {ReactComponent as Icon3} from '../../svg/text_icons/icon3.svg';
import {ReactComponent as Icon4} from '../../svg/text_icons/icon4.svg';

const WhyUsFull = () => {

    const matches = useMediaQuery({ query: '(min-width: 992px)' });
    const matches1 = useMediaQuery({ query: '(min-width: 590px)' });

    return (
        <div className="why_us">
            <div className="container" >
                <Grid fluid>
                    <Row style={{ paddingTop: "50px", display: matches ? "flex": "none" }}>
                        <Col lg={3}>
                            <div style={{ margin: "10px", padding: "30px", height: "auto"}}>
                                <Row style={{ textAlign: "center"}}>
                                    <Icon1 style={{ marginLeft: "auto", marginRight: "auto", bottom: "0"}}/>
                                </Row>
                            </div>
                        </Col>
                        <Col lg={3}>
                            <div style={{ margin: "10px", padding: "30px", height:  "auto"}}>
                                <Row style={{ textAlign: "center"}}>
                                    <Icon2 style={{ marginLeft: "auto", marginRight: "auto", bottom: "-10", position: "relative"}}/>
                                </Row>
                            </div>
                        </Col>
                        <Col lg={3}>
                            <div style={{ margin: "10px", padding: "30px", height: "auto"}}>
                                <Row style={{ textAlign: "center"}}>
                                    <Icon3 style={{ marginLeft: "auto", marginRight: "auto", bottom: "-2", position: "relative"}}/>
                                </Row>
                            </div>
                        </Col>
                        <Col lg={3}>
                            <div style={{ margin: "10px", padding: "30px", height: "auto"}}>
                                <Row style={{ textAlign: "center"}}>
                                    <Icon4 style={{ marginLeft: "auto", marginRight: "auto", bottom: "-40", position: "relative"}}/>
                                </Row>
                            </div>
                        </Col>
                    </Row>

                    {/*Test*/}

                    <div className="gradient-top-to-bottom" style={{ marginBottom: "100px", marginTop: "100px"}}>
                        <Row style={{ paddingTop: "50px", paddingBottom: "10px" }}>
                            <Col lg={4}>
                                <div style={{ margin: "10px", padding: "30px", height: matches1 ? "20vh" : "auto"}}>
                                    <Row >
                                        <div style={{ width: "30px", height: "10px", backgroundColor: "#BEB0FF"}}></div>
                                    </Row>
                                    <Row style={{ textAlign: matches ? "left" : "center"}}>
                                        <Col lg={12}>
                                            <h3 >24x7 CyberSecurity Monitoring</h3>
                                            <span>Provides constant surveillance of company's network and systems to identify and respond to potential threats in real-time.
This includes monitoring for suspicious activity, such as unusual login attempts or changes to sensitive data, and alerting security personnel when potential threats are detected.</span>
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                            <Col lg={4}>
                                <div style={{ margin: "10px", padding: "30px", height: matches1 ? "20vh" : "auto"}}>
                                    <Row >
                                        <div style={{ width: "30px", height: "10px", backgroundColor: "#BEB0FF"}}></div>
                                    </Row>
                                    <Row style={{ textAlign: matches ? "left" : "center"}}>
                                        <Col lg={12}>
                                            <h3 >Threat Detection & Investigation assisted by ML</h3>
                                            <span> We use machine learning algorithms to detect and investigate potential security threats automatically.
By analyzing large amounts of data in real-time, the system can identify patterns or anomalies that may indicate the presence of a threat and alert the appropriate team for further investigation. </span>
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                            <Col lg={4}>
                                <div style={{ margin: "10px", padding: "30px", height: matches1 ? "20vh" : "auto"}}>
                                    <Row >
                                        <div style={{ width: "30px", height: "10px", backgroundColor: "#BEB0FF"}}></div>
                                    </Row>
                                    <Row style={{ textAlign: matches ? "left" : "center"}}>
                                        <Col lg={12}>
                                            <h3 >Incident Response</h3>
                                            <span>In the event of a security incident, we use a structured and organized approach to respond to the situation. The process involves identifying the cause of the incident, containing and mitigating the damage, and restoring any affected systems or data.</span>
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                        </Row>


                        <Row style={{ paddingTop: "50px", paddingBottom: "10px" }}>
                            <Col lg={4}>
                                <div style={{ margin: "10px", padding: "30px", height: matches1 ? "20vh" : "auto"}}>
                                    <Row >
                                        <div style={{ width: "30px", height: "10px", backgroundColor: "#BEB0FF"}}></div>
                                    </Row>
                                    <Row style={{ textAlign: matches ? "left" : "center"}}>
                                        <Col lg={12}>
                                            <h3 >XDR & SIEM included</h3>
                                            <span>
                                                Our MDR services include SIEM and XDR, covering cloud and license costs.
                                                Benefit from real-time threat detection, compliance, and advanced protection across multiple security layers—all with predictable, all-inclusive pricing.
                                             </span>
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                            <Col lg={4}>
                                <div style={{ margin: "10px", padding: "30px", height: matches1 ? "20vh" : "auto"}}>
                                    <Row >
                                        <div style={{ width: "30px", height: "10px", backgroundColor: "#BEB0FF"}}></div>
                                    </Row>
                                    <Row style={{ textAlign: matches ? "left" : "center"}}>
                                        <Col lg={12}>
                                            <h3 >Insider Threat Detection</h3>
                                            <span>
                                                    Insider Threat Detection focuses on detecting and responding to threats that
                                                    originate from within an organization, such as employees or contractors with
                                                    malicious intent or compromised accounts. Our platform includes UEBA (User and
                                                    Entity Behavior Analytics)
                                            </span>
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                            <Col lg={4} style={{ marginBottom: "100px"}}>
                                <div style={{ margin: "10px", padding: "30px", height: matches1 ? "20vh" : "auto"}}>
                                    <Row >
                                        <div style={{ width: "30px", height: "10px", backgroundColor: "#BEB0FF"}}></div>
                                    </Row>
                                    <Row style={{ textAlign: matches ? "left" : "center"}}>
                                        <Col lg={12}>
                                            <h3 >Customizable Solutions</h3>
                                            <span>
                                                Our MDR services are tailored to meet the specific security needs of each client, ensuring that they receive the best possible protection.
                                            </span>
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                    </div>

                </Grid>
            </div>
        </div>
    );
};

export default WhyUsFull;
