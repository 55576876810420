import React from 'react';
import {ReactComponent as Text1} from '../../svg/text1.svg';
import {ReactComponent as Text2} from '../../svg/text2.svg';
import {ReactComponent as Text3} from '../../svg/text3.svg';
import {Col, Grid, Row} from "react-flexbox-grid";
import { Link } from 'react-router-dom'

const Mission = () => {
    return (
        <div className="middle-footer" style={{ backgroundColor: "#414A85", paddingTop: "50px", paddingBottom: "50px"}}>
            <div className="container gradient-4">
                <Grid fluid style={{ padding: "30px"}}>
                    <Row style={{ paddingTop: "40px"}}>
                        <div style={{ width: "30px", height: "10px", backgroundColor: "#BEB0FF"}}></div>
                    </Row>
                    <Row>
                        <Col lg={8}>
                            <h2 style={{color: "#fff", paddingLeft: "10px"}}>
                                Our mission is to help companies
                                <span className="text-purple"> monitor, prevent, detect, investigate, and respond </span>
                                to cyber threats 24/7 with flexible management services
                            </h2>
                        </Col>
                    </Row>

                    <Row style={{ paddingTop: "50px", paddingBottom: "40px" }}>
                        <Col lg={4} style={{paddingRight: "30px", paddingBottom: "30px", paddingLeft: "20px"}}>
                            <Text1 style={{ marginBottom: "20px"}}/>
                            <div className={"gradient-5"} style={{padding: "30px"}}>
                                <h3 style={{ color: "white"}}>Our specialists will do all the
                                    work and we’ll keep you updated on a monthly basis
                                    report or every time a critical incident occurs
                                </h3>
                            </div>
                        </Col>
                        <Col lg={4} style={{paddingRight: "30px", paddingBottom: "30px"}}>
                            <Text2  style={{ marginBottom: "20px"}}/>
                            <div className={"gradient-6"} style={{padding: "30px"}}>
                                <h3 style={{ color: "white"}}>
                                    Our specialists work together with your security
                                    team to provide monitoring detection and response to
                                    alerts in real-time
                                </h3>
                            </div>
                        </Col>
                        <Col lg={4} style={{paddingRight: "30px", paddingBottom: "30px"}}>
                            <Text3 style={{ marginBottom: "30px"}}/>
                            <div className={"gradient-7"} style={{padding: "30px"}}>
                                <h3 style={{ color: "white"}}>Coming soon</h3>
                            </div>
                            <Link to={"https://www.linkedin.com/company/houndbytes/"} target={"_blank"}>
                                <button className={"action-button"} style={{ marginTop: "20px"}}> KEEP ME POSTED</button>
                            </Link>
                        </Col>
                    </Row>

                </Grid>
            </div>
        </div>
    );
};

export default Mission;
