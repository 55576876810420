import React from 'react';
import {Col, Grid, Row} from "react-flexbox-grid";
import mainBg from '../../images/footer.webp';
import { Link } from 'react-router-dom'

const MiddleFooter = () => {


    return (
        <div className="middle-footer" style={{ backgroundImage: `url(${mainBg})`}}>
            <div className="container" >
                <Grid fluid>
                    <Row style={{paddingTop: "70px", paddingLeft: "14px"}}>
                        <div style={{ width: "30px", height: "10px", backgroundColor: "#BEB0FF"}}></div>
                    </Row>
                    <Row>
                        <Col lg={8}>
                            <h2 style={{ color: "#fff", paddingLeft: "30px"}}>
                                We are committed to sharing our knowledge, expertise, and experience
                                <span className="text-purple">&nbsp;free of charge</span>
                            </h2>
                        </Col>
                    </Row>
                    <Row style={{ paddingTop: "50px", paddingBottom: "50px" }}>
                        <Col lg={6} style={{ paddingRight: "30px", paddingBottom: "30px"}}>
                            <div className={"gradient-5"} style={{padding: "30px"}}>
                                <h3 style={{ color: "white"}}>Free security assessment for your business</h3>
                                <Link to="/contact">
                                    <button className={"action-button"} style={{ marginTop: "20px" }}> LET'S TALK </button>
                                </Link>
                            </div>
                        </Col>
                        <Col lg={6} style={{paddingRight: "30px", paddingBottom: "30px"}}>
                            <div className={"gradient-7"} style={{padding: "30px"}}>
                                <h3 style={{ color: "white"}}>Free security policies and procedures review</h3>
                                <Link to={"/contact"}>
                                    <button className={"action-button"} style={{ marginTop: "20px"}}> LET'S TALK </button>
                                </Link>
                            </div>
                        </Col>
                    </Row>
                </Grid>
            </div>
        </div>
    );
};

export default MiddleFooter;
