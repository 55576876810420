import React from 'react';
import { Col, Grid, Row } from 'react-flexbox-grid';
import { useMediaQuery } from 'react-responsive';
import { TextField } from '@mui/material';
import { styled } from '@mui/material/styles';
import apm from '../../apm';

const CssTextField = styled(TextField)({
    '& label.Mui-focused': {
        color: 'black',
    },
    '& .MuiInput-underline:after': {
        borderBottomColor: '#BEB0FF',
    },
    '& .MuiFilledInput-root:after': {
        borderColor: '#BEB0FF',
    },
    '& .MuiFilledInput-root': {
        backgroundColor: 'white',
        '& fieldset': {
            borderColor: '#E0E3E7',
        }
    },
});

const HeroContact = ({ title, description}) => {

    const matches = useMediaQuery({ query: '(min-width: 992px)' });
    const [data, setData] = React.useState({
        firstName: "",
        lastName: "",
        companyName: "",
        industry: "",
        employeeNo: "",
        businessEmail: "",
        phoneNo: ""
    });


    function submitContact () {
        let transaction = apm.startTransaction('submitContact', 'contact-form')
        fetch('https://api.analytika.live/notifications/contact', {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(response => {
            transaction.end()
            if (response.status === 200) {
                alert("Thank you for contacting us. We will get back to you soon.")
            } else {
                alert("Something went wrong. Please try again later.")
                apm.captureError("Failed to submit contact form: " + response.status)
            }
        }).catch(error => {
            transaction.end()
            alert("Something went wrong. Please try again later.")
            apm.captureError("Failed to submit contact form: " + error)
        })
    }

    return (
        <div className="middle-footer gradient-bottom-to-top" key={"contact-form"}>
            <div className="container" >
                <Grid fluid>
                    <Row style={{ paddingTop: "250px"}}>
                        <div style={{ width: "30px", height: "10px", backgroundColor: "#BEB0FF"}}></div>
                    </Row>
                    <Row style={{ paddingBottom: "180px", justifyContent: "space-between"}}>
                        <Col lg={5}>
                            <h4 style={{color: "black"}}>{ title }</h4>
                            <h1 style={{color: "black"}}>{description}</h1>
                        </Col>
                        <Col lg={6}>
                            <Row>
                                <Col lg={6}> <CssTextField  fullWidth label="First Name" variant="filled" name="firstName" onChange={(e) => setData({...data, firstName: e.target.value})} key="firstName" value={data.firstName} /> </Col>
                                <Col lg={6}> <CssTextField fullWidth  label="Last Name" variant="filled" name="lastName" onChange={(e) => setData({...data, lastName: e.target.value})} key="lastName" value={data.lastName} /> </Col>
                            </Row>
                            <Row style={{ marginTop: "10px"}}>
                                <Col lg={12}> <CssTextField fullWidth  label="Company name" variant="filled" name="companyName" onChange={(e) => setData({...data, companyName: e.target.value})} key="companyName" value={data.companyName} /> </Col>
                            </Row>

                            <Row style={{ marginTop: "10px"}}>
                                <Col lg={6}> <CssTextField fullWidth  label="Industry" variant="filled" name="industry" onChange={(e) => setData({...data, industry: e.target.value})} /> </Col>
                                <Col lg={6}> <CssTextField fullWidth  label="No. of employee" variant="filled" name="employeeNo" onChange={(e) => setData({...data, employeeNo: e.target.value})} /> </Col>
                            </Row>

                            <Row style={{ marginTop: "10px"}}>
                                <Col lg={6}> <CssTextField fullWidth  label="Business email" variant="filled" name="businessEmail" onChange={(e) => setData({...data, businessEmail: e.target.value})} /> </Col>
                                <Col lg={6}> <CssTextField fullWidth  label="Phone No." variant="filled" name="phoneNo" onChange={(e) => setData({...data, phoneNo: e.target.value})} /> </Col>
                            </Row>

                            <Row style={{ paddingTop: "0px", paddingBottom: "80px", marginTop: "20px"}}>
                                <Col lg={6}>
                                    {/*<a target="_blank" href="https://www.linkedin.com/company/houndbytes/">*/}
                                        <button className={'action-button'} onClick={submitContact}> LETS TALK</button>
                                    {/*</a>*/}
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Grid>
            </div>
        </div>
    );
};

export default HeroContact;
