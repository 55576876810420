import React from 'react';
import {Col, Grid, Row} from "react-flexbox-grid";
import {useMediaQuery} from "react-responsive";

import {ReactComponent as TeamIcon} from '../../svg/person.svg';

const TeamFull = () => {

    const matches = useMediaQuery({ query: '(min-width: 992px)' });
    const matches1 = useMediaQuery({ query: '(min-width: 590px)' });

    return (
        <div className="middle-footer  gradient-bottom-to-top" style={{ backgroundColor: "gray"}}>
            <div className="container" >
                <Grid fluid>

                    <Row>
                        <Col lg={8} style={{ display: "block", marginTop: "20px", textAlign: matches ? "left": "center"}}>
                            <h1>Meet our team</h1>
                        </Col>
                    </Row>



                    <Row style={{ paddingTop: "50px", paddingBottom: "10px" }}>
                        <Col lg={4}>
                            {/*<div style={{ margin: "10px", padding: "30px", height: matches1 ? "45vh" : "auto"}}>*/}
                            <div style={{ margin: "10px", padding: "30px"}}>

                                <Row style={{ textAlign: matches ? "left" : "center"}}>
                                    <img src={"images/marius_corici.jpeg"} style={{ height: "20vh", width: "auto", borderRadius: '15px'}}/>
                                </Row>
                                <Row style={{ textAlign: matches ? "left" : "center",  display: "block"}}>
                                    <h4 >Marius Corici</h4>
                                </Row>
                                <Row style={{ textAlign: matches ? "left" : "center", marginTop: "-15px", display: "block"}}>
                                    <h4 >CEO & Co-founder</h4>
                                </Row>

                                <Row style={{ textAlign: matches ? "left" : "center"}}>
                                    <span style={{ maxWidth: "300px", margin: matches ? "0px" : "0px auto"}}>
                                        Marius Corici, based in Cluj-Napoca, CJ, RO, is currently a CEO and Co-founder at HoundBytes, bringing experience
                                        from previous roles at OWASP Foundation, ACP - Air Conditioning Products, CTF365 and Innovation Labs Romania.
                                        {/*With a robust skill set that includes Security Research, Information Security, Strategic Partnerships, Online Marketing,*/}
                                        {/*Start Ups and more, Marius Corici contributes valuable insights to the industry. Marius Corici has 4 emails and 3 mobile*/}
                                        {/*phone numbers on RocketReach.*/}
                                    </span>
                                </Row>

                                <Row style={{ textAlign: matches ? "left" : "center", marginTop: "20px", display: "block"}}>
                                    <a href="https://www.linkedin.com/in/mariuscorici/" target={"_blank"}>Linkedin</a>
                                </Row>
                                {/*<Row style={{ textAlign: matches ? "left" : "center", marginTop: "10px", display: "block"}}>*/}
                                {/*    <a href="https://www.facebook.com/marius.corici"  target={"_blank"}>Facebook</a>*/}
                                {/*</Row>*/}
                            </div>
                        </Col>
                        <Col lg={4}>
                            <div style={{ margin: "10px", padding: "30px", height: matches1 ? "45vh" : "auto"}}>

                                <Row style={{ textAlign: matches ? "left" : "center"}}>
                                    <img src={'images/andrei_ghiciac.png'} style={{ height: "20vh", width: "auto", borderRadius: '15px'}}/>
                                </Row>
                                <Row style={{ textAlign: matches ? "left" : "center", display: "block"}}>
                                    <h4>Andrei Ghiciac</h4>
                                </Row>
                                <Row style={{ textAlign: matches ? "left" : "center", marginTop: "-15px", display: "block"}}>
                                    <h4 >CISO & Co-Founder</h4>
                                </Row>

                                <Row style={{ textAlign: matches ? "left" : "center"}}>
                                    <span style={{ maxWidth: "300px", margin: matches ? "0px" : "0px auto"}}>
                                        Andrei leads the SOC team, providing top-tier threat monitoring and response.
                                        He supports business development with technical expertise, mentors the security team, and defines security strategies and procedures,
                                        ensuring robust protection and compliance for Houndbytes and its clients.
                                    </span>
                                </Row>

                                <Row style={{ textAlign: matches ? "left" : "center", marginTop: "20px", display: "block"}}>
                                    <a href="https://www.linkedin.com/in/andrei-ghiciac-51105a261/"  target={"_blank"}>Linkedin</a>
                                </Row>
                                {/*<Row style={{ textAlign: matches ? "left" : "center", marginTop: "10px", display: "block"}}>*/}
                                {/*    <a href=""  target={"_blank"}>Facebook</a>*/}
                                {/*</Row>*/}
                            </div>
                        </Col>
                        <Col lg={4}>
                            <div style={{ margin: "10px", padding: "30px", height: matches1 ? "45vh" : "auto"}}>

                                <Row style={{ textAlign: matches ? "left" : "center"}}>
                                    <img src={"images/bogdan_herea.jpeg"} style={{ height: "20vh", width: "auto%", borderRadius: '15px'}}/>
                                </Row>
                                <Row style={{ textAlign: matches ? "left" : "center", display: "block"}}>
                                    <h4>Bogdan Herea</h4>
                                </Row>
                                <Row style={{ textAlign: matches ? "left" : "center", marginTop: "-15px", display: "block"}}>
                                    <h4 >Advisory Board</h4>
                                </Row>.

                                <Row style={{ textAlign: matches ? 'left' : 'center' }}>
                                    <span style={{ maxWidth: '300px', margin: matches ? '0px' : '0px auto' }}>
                                        Board Director & Advisor | Mission-driven Leadership | Business Coach | Tech Business Consultant
                                    </span>
                                    <br/>
                                    <span style={{ maxWidth: '300px', margin: matches ? '0px' : '0px auto' }}>
                                        Serial Entrepreneur acting also as a business coach, providing mentorship to HoundBytes
                                    </span>
                                </Row>

                                <Row style={{ textAlign: matches ? 'left' : "center", marginTop: "20px", display: "block"}}>
                                    <a href="https://www.linkedin.com/in/bogdanherea/"  target={"_blank"}>Linkedin</a>
                                </Row>
                                {/*<Row style={{ textAlign: matches ? "left" : "center", marginTop: "10px", display: "block"}}>*/}
                                {/*    <a href="">Facebook</a>*/}
                                {/*</Row>*/}
                            </div>
                        </Col>
                        {/*<Col lg={4}>*/}
                        {/*    <div style={{ margin: "10px", padding: "30px", height: matches1 ? "45vh" : "auto"}}>*/}

                        {/*        <Row style={{ textAlign: matches ? "left" : "center"}}>*/}
                        {/*            <TeamIcon style={{ height: "20vh", width: "100%"}}/>*/}
                        {/*        </Row>*/}
                        {/*        <Row style={{ textAlign: matches ? "left" : "center", display: "block"}}>*/}
                        {/*            <h4 >Andrei Nita</h4>*/}
                        {/*        </Row>*/}
                        {/*        <Row style={{ textAlign: matches ? "left" : "center", marginTop: "-15px", display: "block"}}>*/}
                        {/*            <h4 >SOC Manager</h4>*/}
                        {/*        </Row>*/}

                        {/*        <Row style={{ textAlign: matches ? "left" : "center"}}>*/}
                        {/*            <span style={{ maxWidth: "300px", margin: matches ? "0px" : "0px auto"}}>*/}
                        {/*                With expertise from Novartis and NATO, Andrei brings unparalleled skills to our team. As SOC Manager,*/}
                        {/*                he enhances our Managed Detection and Response (MDR) services with strategic insights and a deep passion for cybersecurity.*/}
                        {/*            </span>*/}
                        {/*        </Row>*/}

                        {/*        <Row style={{ textAlign: matches ? "left" : "center", marginTop: "20px", display: "block"}}>*/}
                        {/*            <a href="">Linkedin</a>*/}
                        {/*        </Row>*/}
                        {/*        /!*<Row style={{ textAlign: matches ? "left" : "center", marginTop: "10px", display: "block"}}>*!/*/}
                        {/*        /!*    <a href="">Facebook</a>*!/*/}
                        {/*        /!*</Row>*!/*/}
                        {/*    </div>*/}
                        {/*</Col>*/}
                    </Row>


                    <Row style={{ paddingTop: "50px", paddingBottom: "10px" }}>

                        {/*<Col lg={4}>*/}
                        {/*    <div style={{ margin: "10px", padding: "30px", height: matches1 ? "45vh" : "auto"}}>*/}

                        {/*        <Row style={{ textAlign: matches ? "left" : "center"}}>*/}
                        {/*            <TeamIcon style={{ height: "20vh", width: "100%"}}/>*/}
                        {/*        </Row>*/}
                        {/*        <Row style={{ textAlign: matches ? "left" : "center", display: "block"}}>*/}
                        {/*            <h4>Cristian Grigoras</h4>*/}
                        {/*        </Row>*/}
                        {/*        <Row style={{ textAlign: matches ? "left" : "center", marginTop: "-15px", display: "block"}}>*/}
                        {/*            <h4 >Red Team Lead</h4>*/}
                        {/*        </Row>*/}

                        {/*        <Row style={{ textAlign: matches ? "left" : "center"}}>*/}
                        {/*            <span style={{ maxWidth: "300px", margin: matches ? "0px" : "0px auto"}}>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s</span>*/}
                        {/*        </Row>*/}

                        {/*        <Row style={{ textAlign: matches ? "left" : "center", marginTop: "20px", display: "block"}}>*/}
                        {/*            <a href="">Linkedin</a>*/}
                        {/*        </Row>*/}
                        {/*        <Row style={{ textAlign: matches ? "left" : "center", marginTop: "10px", display: "block"}}>*/}
                        {/*            <a href="">Facebook</a>*/}
                        {/*        </Row>*/}
                        {/*    </div>*/}
                        {/*</Col>*/}
                        {/*<Col lg={4}>*/}
                        {/*    <div style={{ margin: "10px", padding: "30px", height: matches1 ? "45vh" : "auto"}}>*/}

                        {/*        <Row style={{ textAlign: matches ? "left" : "center"}}>*/}
                        {/*            <TeamIcon style={{ height: "20vh", width: "100%"}}/>*/}
                        {/*        </Row>*/}
                        {/*        <Row style={{ textAlign: matches ? "left" : "center", display: "block"}}>*/}
                        {/*            <h4>Mihai Popescu</h4>*/}
                        {/*        </Row>*/}
                        {/*        <Row style={{ textAlign: matches ? "left" : "center", marginTop: "-15px", display: "block"}}>*/}
                        {/*            <h4 >CTO</h4>*/}
                        {/*        </Row>*/}

                        {/*        <Row style={{ textAlign: matches ? "left" : "center"}}>*/}
                        {/*            <span style={{ maxWidth: "300px", margin: matches ? "0px" : "0px auto"}}>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s</span>*/}
                        {/*        </Row>*/}

                        {/*        <Row style={{ textAlign: matches ? "left" : "center", marginTop: "20px", display: "block"}}>*/}
                        {/*            <a href="">Linkedin</a>*/}
                        {/*        </Row>*/}
                        {/*        <Row style={{ textAlign: matches ? "left" : "center", marginTop: "10px", display: "block"}}>*/}
                        {/*            <a href="">Facebook</a>*/}
                        {/*        </Row>*/}
                        {/*    </div>*/}
                        {/*</Col>*/}
                    </Row>

                </Grid>
            </div>
        </div>
    );
};

export default TeamFull;
