import React, {useEffect} from 'react';
import Menu from "../components/menu/Menu";
import HeroImage from "../components/hero image/HeroImage";
import Footer from "../components/footer/Footer";
import FullFeatures from "../components/full_features/FullFeatures";

const KeyFeatures = ({ setMenuWhiteTheme }) => {

    useEffect(() => {
        setMenuWhiteTheme(true);
    }, []);

    return (
        <div>
            <HeroImage title="" description="Key Features" />
            <FullFeatures/>
            <Footer/>
        </div>
    );
};

export default KeyFeatures;
